window.onload = () => {
  let loadedSchema = undefined

  const registersAndValues = {};

  window.ahu2020.outgoing = (stringMsg) => {
    const msg = JSON.parse(stringMsg);
    if (msg.type === 'write_registers') {
      const { requestId, data } = msg;
      data.forEach(([reg, value]) => {
        registersAndValues[reg] = value;
      });
      const response = {
        type: 'response',
        response: {
          type: 'write_registers',
          success: true,
          requestId,
          data,
        },
      };
      setTimeout(() => {
        window.ahu2020.incoming(JSON.stringify(response));
      }, Math.random() * 2500);
    } else if (msg.type === 'read_registers') {
      const registers = msg.registers;
      const data = registers.map((register) => [
        register,
        registersAndValues[register],
      ]);
      const response = {
        type: 'response',
        response: {
          type: 'read_registers',
          success: true,
          requestId: msg.requestId,
          data,
        },
      };
      setTimeout(() => {
        window.ahu2020.incoming(JSON.stringify(response));
      }, 100);
    } else if (msg.type === 'reconnect') {
      const response = {
        type: 'response',
        response: {
          type: 'reconnect',
          success: true,
          requestId: msg.requestId,
        },
      };
      setTimeout(() => {
        window.ahu2020.incoming(JSON.stringify(response));
      }, 1000);
    }
  };

  const urlParams = new URLSearchParams(window.location.search);
  const language = urlParams.get('language');
  const schemaFromUrl = urlParams.get('schema');
  const allowedSchemas = ['resident', 'technician'];
  const schema = allowedSchemas.includes(schemaFromUrl) ? schemaFromUrl : 'resident';
  const schemaFile = `schema.${schema}.json`;

  fetch(schemaFile).then(response => response.text())
    .then(schemaString => {
      console.log('Loaded ' + schemaFile);

      try {
        loadedSchema = JSON.parse(schemaString);
        loadedSchema.parameters.forEach((parameter) => {
          registersAndValues[parameter.register] = parameter.defaultValue;
        });
      } catch {}

      window.ahu2020.incoming(schemaString);

      window.ahu2020.incoming(
        JSON.stringify({
          type: 'config',
          language: language || undefined,
          quitEnabled: false,
        })
      );
    })
    .catch(err => {
      console.log(err)
      window.alert('Missing schema.json. There should be a schema.json file in the same directory as the index.html.')
    })
};
